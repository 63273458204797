import React from "react";
import Panel from "../BasePanel/Panel";
import { observer } from "mobx-react";
import { useStore } from "../../../../../Hooks";
import { Property, LinkProperty, IconProperty } from "./Properties";
import PersonListElem from "../ListElems/Person";
import { FedLeg, StateLeg } from "APTAPackage";
import {
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  HatenaShareButton,
  InstapaperShareButton,
  LineShareButton,
  LinkedinShareButton,
  LivejournalShareButton,
  MailruShareButton,
  OKShareButton,
  PinterestShareButton,
  PocketShareButton,
  RedditIcon,
  RedditShareButton,
  TelegramShareButton,
  TumblrShareButton,
  TwitterIcon,
  TwitterShareButton,
  ViberShareButton,
  VKShareButton,
  WhatsappShareButton,
  WorkplaceShareButton,
} from "react-share";
import "./PersonPanel.css";
import { sendGAEvent, sendGAEventPerson } from "../../../../../Hooks/useGoogleAnalytics";

function isFed(leg: FedLeg | StateLeg): leg is FedLeg {
  return (leg as FedLeg).cspan_id !== undefined;
}

const PersonPanel: React.FC = () => {
  const panelStore = useStore("panelStore");
  const activePerson = panelStore.getActivePerson;


  if (activePerson === undefined) {
    return <Panel />;
  }

  return (
    <Panel>
      <PersonListElem person={activePerson} solo={true} district={true} />
      <table className="gm-person-panel-table">
        <tbody>
          {isFed(activePerson) ? (
            <React.Fragment>
              <Property
                title="Votes with party"
                data={`${
                  activePerson?.votes_with_party_pct == null
                    ? "N/A"
                    : `${activePerson?.votes_with_party_pct}%`
                }`}
              />
              <Property
                title="Next Election"
                data={activePerson?.next_election as string}
              />

              <Property
                title="Phone Number"
                data={activePerson?.contactInfo?.phone1 as string}
              />
              <Property
                title="Office"
                data={activePerson?.contactInfo?.address1 as string}
              />
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Property
                title="Email"
                data={activePerson?.contactInfo?.email as string}
              />

              <Property
                title="District Fax"
                data={activePerson?.contactInfo?.fax2 as string}
              />
              <Property
                title="District Phone"
                data={activePerson?.contactInfo?.phone2 as string}
              />

              <Property
                title="Capitol Fax"
                data={activePerson?.contactInfo?.fax1 as string}
              />
              <Property
                title="Capitol Phone"
                data={activePerson?.contactInfo?.phone1 as string}
              />
            </React.Fragment>
          )}

          <LinkProperty
            title="Website URL"
            href={activePerson?.contactInfo?.urls[0] as string}
            onClick={() => {
              sendGAEventPerson({
                eventName: "external_link",
                eventLabel: "extenal_link",
                person: activePerson,
                otherParams: { 'external_link_type': 'legislator_site'}
              });
            }}
          />
          <LinkProperty
            title="Contact URL"
            href={activePerson?.contactInfo?.urls[1] as string}
          />
        </tbody>
      </table>
      <div className="fed-icons-parent">
        <hr />
        <div className="fed-icons">
          <IconProperty
            data={panelStore.activePerson?.contactInfo?.twitter as string}
            image="/images/icons/twitter.png"
            href={`http://www.twitter.com/${panelStore.activePerson?.contactInfo?.twitter}`}
            onClick={() => {
              sendGAEventPerson({
                eventName: "external_link",
                eventLabel: "extenal_link",
                person: activePerson,
                otherParams: { 'external_link_type': 'twitter'}
              });
            }}
          />
          <IconProperty
            data={panelStore.activePerson?.contactInfo?.facebook as string}
            image="/images/icons/facebook.png"
            href={`http://www.facebook.com/${panelStore.activePerson?.contactInfo?.facebook}`}
            onClick={() => {
              sendGAEventPerson({
                eventName: "external_link",
                eventLabel: "extenal_link",
                person: activePerson,
                otherParams: { 'external_link_type': 'facebook'}
              });
            }}
          />
          <IconProperty
            data={panelStore.activePerson?.contactInfo?.youtube as string}
            image="/images/icons/youtube.png"
            href={`http://www.youtube.com/${panelStore.activePerson?.contactInfo?.youtube}`}
            onClick={() => {
              sendGAEventPerson({
                eventName: "external_link",
                eventLabel: "extenal_link",
                person: activePerson,
                otherParams: { 'external_link_type': 'youtube'}
              });
            }}
          />
        </div>
      </div>
    </Panel>
  );
};

export default observer(PersonPanel);
