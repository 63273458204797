import React, { useMemo } from "react";
import { Counties, CoreData } from "APTAPackage";
import { DataSummaryItem } from "./SummaryItem";

import { useHistory } from "react-router-dom";
import { useStore } from "../../../../Hooks";
import { convertNumber } from "../../../../components/Pages/Print/helpers";
import { SectionTitle } from "../../Components/SectionTitle";
import { T_dataComponent } from "../../../types";
import { CountyTable } from "./CountyTable";
import LoadingSpinner from "../../../../components/Pages/Icons/LoadingSpinner";

import { createColumnHelper } from "@tanstack/react-table";

type T_props = {
  countyData: Counties;
  coreData: CoreData;
  dataLoading: boolean;
  type: T_dataComponent;
  dataView: "upper" | "lower" | "both";
};

type T_county = {
  name: string;
  pct_transit: string;
  pop_public_trans: string;
  pop_commuting: string;
  med_income: string;
  no_vehicle_households: string;
  traffic_vol_score: string;
};

const DataSummary: React.FC<T_props> = ({
  countyData,
  coreData,
  dataLoading,
  type,
  dataView,
}) => {
  const history = useHistory();
  const panelStore = useStore("panelStore");
  const pathName = history.location.pathname.toLowerCase();
  const crumbString = panelStore.crumbStrings;

  const state = coreData?.identityInfo?.stateAbbrev
    ? coreData.identityInfo.stateAbbrev
    : crumbString[1];

  const seatName = useMemo(() => {
    const isHouse = pathName.includes("house");
    const isUpperChamb = pathName.includes("sldu");
    const isLowerChamb = pathName.includes("sldl");
    const isFed = pathName.includes("senate");

    const upperFilter =
      isUpperChamb &&
      crumbString?.[3]?.slice(crumbString?.[3]?.lastIndexOf("-") + 1);
    const lowerFilter =
      isLowerChamb &&
      crumbString?.[3]?.slice(crumbString?.[3]?.lastIndexOf("-") + 1);

    const seatName = isFed
      ? "Statewide"
      : isHouse
      ? `Congressional District ${crumbString[2]}`
      : isUpperChamb
      ? `Upper Chamber ${upperFilter}`
      : isLowerChamb
      ? `Lower Chamber ${lowerFilter}`
      : "Statewide";

    return seatName;
  }, [pathName, crumbString]);

  const columnHelper = createColumnHelper<T_county>();

  const columns = [
    columnHelper.accessor("name", {
      cell: (info) => (
        <span className={`${getExploreTextClass()}`}>
          {" "}
          {info.renderValue()}{" "}
        </span>
      ),
      footer: (info) => info.column.id,
      header: () => <span>County</span>,
      size: 150,
    }),
    columnHelper.accessor("pop_commuting", {
      cell: (info) => (
        <span className={`${getExploreNumericClass()}`}>
          {" "}
          {info.renderValue()}{" "}
        </span>
      ),
      header: () => <span>Commuters</span>,
      size: 150,
    }),
    columnHelper.accessor("pop_public_trans", {
      cell: (info) => (
        <span className={`${getExploreNumericClass()}`}>
          {" "}
          {info.renderValue()}{" "}
        </span>
      ),
      header: () => <span>Transit Commuters</span>,
      size: 190,
    }),
    columnHelper.accessor("med_income", {
      cell: (info) => (
        <span className={`${getExploreNumericClass()}`}>
          {" "}
          {info.renderValue()}{" "}
        </span>
      ),
      header: () => <span>Median Income</span>,
      size: 150,
    }),
    columnHelper.accessor("no_vehicle_households", {
      cell: (info) => (
        <span className={`${getExploreNumericClass()}`}>
          {" "}
          {info.renderValue()}{" "}
        </span>
      ),
      header: "Zero Vehicle Households",
      size: 230,
    }),
    columnHelper.accessor("traffic_vol_score", {
      cell: (info) => (
        <span className={`  ${getExploreTextClass()}`}>
          {" "}
          {info.renderValue()}{" "}
        </span>
      ),
      header: "Traffic Volume",
      size: 150,
    }),
  ];

  const { data } = useMemo(() => {
    let counties = countyData?.counties || [];

    const rows = counties?.map((county, index) => {
      //@ts-ignore
      const countyName: string = county?.NAME
        ? (county.NAME as string)
          ? county?.NAME10
          : (county.NAME10 as string)
        : county?.NAMELSAD10
        ? (county.NAMELSAD10 as string)
        : "N/A";

      const pct_transit = county?.pct_transit;
      const pop_public_trans = county?.pop_public_trans;
      const pop_commuting = county?.pop_commuting;
      const med_income = county?.med_income;
      const no_vehicle_households = county?.no_vehicle_households;

      const percent = pct_transit
        ? Math.round(pct_transit * 100) >= 1
          ? `${(pct_transit * 100).toFixed()}%`
          : "<1%"
        : "N/A";

      const publicTrans = pop_public_trans
        ? convertNumber(pop_public_trans)
        : "N/A";
      const commuters = pop_commuting ? convertNumber(pop_commuting) : "N/A";

      const med = pop_commuting ? convertNumber(med_income) : "N/A";
      const noV = pop_commuting ? convertNumber(no_vehicle_households) : "N/A";

      //@ts-ignore
      const traffic_vol_score = county?.traffic_vol_score
        ? county?.traffic_vol_score
        : "N/A";

      const parsedCounty: T_county = {
        name: countyName,
        pct_transit: percent,
        pop_public_trans: publicTrans,
        pop_commuting: commuters,
        no_vehicle_households: noV,
        med_income: med,
        traffic_vol_score,
      };

      return parsedCounty;
    });

    return { data: rows };
  }, [countyData]);

  const getExploreTextClass = () => {
    switch (type) {
      case "POSTCARD":
        return "gm-explore-text-3-postcard";
      case "FULL_PAGE":
        return "gm-explore-text-3";
      case "WEBSITE":
        return "gm-explore-text-3";
    }
  };

  const getExploreNumericClass = () => {
    switch (type) {
      case "POSTCARD":
        return "gm-explore-numeric-4-postcard";
      case "FULL_PAGE":
        return "gm-explore-numeric-4";
      case "WEBSITE":
        return "gm-explore-numeric-4";
    }
  };

  return (
    <div
      style={{
        breakBefore: "auto",
        breakAfter: "avoid",
        breakInside: "avoid",
        clear: "both",
      }}
    >
      <SectionTitle title="Data Summary" type={type}>
        {dataLoading ? (
          <LoadingSpinner color="var(--apta-navbar-text-color)" />
        ) : (
          <>
            {dataView === "upper" ||
              (dataView === "both" && (
                <div
                  className="row pb-3"
                  style={{ breakBefore: "avoid", breakInside: "auto" }}
                >
                  <div className="col ">
                    <div className="row">
                      <div className="col gm-explore-text-2 pb-2">
                        <b>State:</b> {state}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col gm-explore-text-2 pb-2">
                        <b>Region:</b> {seatName}
                      </div>
                    </div>
                  </div>
                  <div className="col-sm text-center">
                    <DataSummaryItem
                      title="Unlinked Passenger Trips"
                      ammount={coreData?.sum_passenger_trips}
                    />
                  </div>
                  <div className="col-sm text-center">
                    <DataSummaryItem
                      title="Passenger Miles Travelled"
                      ammount={coreData?.sum_passenger_miles}
                    />
                  </div>
                </div>
              ))}

            <CountyTable columns={columns} rows={data} type={type} />
          </>
        )}
      </SectionTitle>
    </div>
  );
};

export default DataSummary;
